import React, { useEffect } from "react";
import Box from "../../components/Box";
import ConnectingAnimation from "../../components/ConnectingAnimation";
import Fetch from "../../services/FetchService";

export default function ReturnFromDexcom({
  trustDetails,
  token,
  authCode,
  handleSuccess,
  handleFailure,
}) {
  useEffect(() => {
    (async function () {
      var startTime = Date.now();
      const requestBody = {
        patientRegistrationToken: token,
        org: trustDetails.apiShortName,
        authorizationCode: authCode,
      };
      let success = false;
      Fetch("/api/PutPatient", requestBody)
        .then((response) => {
          success = response;
          if (success) {
            // Display this screen for at least 2.5 seconds
            var timeDelta = Date.now() - startTime;
            setTimeout(handleSuccess, 2500 - timeDelta);
          } else {
            handleFailure();
          }
        })
        .catch((error) => {
          console.error(error);
          handleFailure();
        });
    })();
  }, [trustDetails, token, authCode, handleFailure, handleSuccess]);

  return (
    <Box>
      <p>Welcome back. Thanks for approving.</p>
      <ConnectingAnimation />
      <p>We&rsquo;re getting everything set up.</p>
    </Box>
  );
}
