import Box from "../components/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

const Error = () => {
  return (
    <div className="wrapper">
      <Box>
        <p>
          <FontAwesomeIcon icon={icon({ name: "bug", style: "solid" })} />{" "}
          Sorry, an error has occurred. Please try again.
        </p>
      </Box>
    </div>
  );
};

export default Error;
