import React from "react";
import Box from "../../components/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function Failure() {
  return (
    <Box>
      <p>
        <FontAwesomeIcon
          icon={icon({ name: "triangle-exclamation", style: "solid" })}
        />{" "}
        Sorry, that hasn’t worked.
      </p>
      <p>Please try again using the individual link you were given.</p>
    </Box>
  );
}
