import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DexcomLayout from "./layouts/DexcomLayout";
import AuthFlow from "./authFlow/AuthFlow";
import GetPatientStatus from "./getPatientStatus/GetPatientStatus";
import Layout from "./layouts/Layout";
import HealthCheck from "./HealthCheck";
import Error from "./error/Error";
import PrivacyNotice from "./components/PrivacyNotice";
import "./config";
import "./scss/styles.scss";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<Layout />}>
          <Route path="" element={<Error />} />
        </Route>
        <Route path="/" element={<Layout />}>
          <Route path="HealthCheck" element={<HealthCheck />} />
          <Route path=":trust">
            <Route path="dexcom" element={<DexcomLayout />}>
              <Route path="" element={<Error />} />
              <Route path="*" element={<Error />} />
              <Route path="share" element={<AuthFlow />} />
              <Route path="patient-status" element={<GetPatientStatus />} />
              <Route path="privacy" element={<PrivacyNotice />} />
            </Route>
            <Route path="" element={<Error />} />
            <Route path="*" element={<Error />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
