import "../scss/components/footer.scss";

export default function Footer() {
  return (
    <footer>
      <div className="wrapper">
        <div className="flex">
          <span>© {new Date().getFullYear()} Factor 50</span>
          <a href="privacy">Privacy Notice</a>
        </div>
      </div>
    </footer>
  );
}
