import React from "react";

export default function Label({ children, text }) {
  return (
    <label className="form-field">
      <span>{text}:</span>
      {children}
    </label>
  );
}
