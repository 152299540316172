import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import GetTrust from "../services/TrustService";

export default function PrivacyNotice() {
  const { trust } = useParams();
  let trustDetails = GetTrust(trust);
  let supplier = "Dexcom";

  useEffect(() => {
    document.title = `${trustDetails.trustName} | Data-Led Care | Privacy Notice`;
  });
  return (
    <>
      <h1>Privacy Notice</h1>
      <p>
        This privacy notice is for individuals linking their {supplier}{" "}
        Continuous Glucose Monitoring (CGM) data to their{" "}
        {trustDetails.trustName} records for the purpose of direct patient care
        through a process known as Data Led Prioritisation (DLP).
      </p>
      <p>
        DLP is a process by which clinical data from a variety of sources (which
        includes {supplier} CGM data) is used to inform decision-making about a
        patient&rsquo;s care.
      </p>
      <p>
        As part of this process we will collect personal information directly
        from you to identify you and your {supplier} records, and indirectly via
        a third party ({supplier}). The following sections will explain how your
        data has been collected and how it will be used by us.
      </p>
      <h3>The data controller</h3>
      <p>
        The data controller is defined in the UK GDPR as the authority which
        determines the purposes and means of the processing of personal data.
        The Controller for your data is {trustDetails.dataController.name}. You
        may contact:
      </p>
      <ul>
        <li>
          {trustDetails.dataController.contactName},{" "}
          <a href={"mailto:" & trustDetails.dataController.contactEmail}>
            {trustDetails.dataController.contactEmail}
          </a>
        </li>
        <li>or write to {trustDetails.dataController.contactAddress}</li>
      </ul>
      <h3>Categories of personal data</h3>
      <p>
        We will capture, from you directly, the following types of personal
        data:
      </p>
      <ul>
        <li>Date of Birth</li>
      </ul>
      <p>
        We will capture, from a third party ({supplier}), the following types of
        personal data:
      </p>
      <ul>
        <li>
          CGM data (including estimated glucose value (EGV), EGV trend, device
          and user information) for the fourteen days preceding date of data
          extraction. Data will be extracted at least daily. CGM data is
          typically available at five minute intervals. Note, this data (and all
          health information) is classed as special category data.
        </li>
      </ul>
      <h3>Lawful basis</h3>
      <p>
        The lawful basis of processing as required by UK GDPR Article 6(1) is
        point (a), the individual has given clear consent to process their data
        for a specific purpose. This purpose is linking {supplier} Continuous
        Glucose Monitoring (CGM) data to {trustDetails.trustName} records for
        the purpose of direct patient care through a process known as Data Led
        Prioritisation (DLP).
      </p>
      <p>
        The lawful basis for processing special category data as required by UK
        GDPR Article 9(2) is point (a), explicit consent.
      </p>
      <h3>Who we will share your data with</h3>
      <p>
        We will not share your data outside our organisation except for the
        third-party data processors we use to undertake necessary processing. We
        will ensure that any processor we use is compliant with the UK GDPR to
        ensure your data is handled appropriately at all times. A list of
        processors can be found below:
      </p>
      <ul>
        <li>Factor 50 Ltd</li>
      </ul>
      <h3>International transfers</h3>
      <p>
        We will select processors that carry out processing within the UK, the
        European Economic Area (EEA), or any other &lsquo;adequacy&rsquo;
        country. We will not transfer your data outside of these territories.
      </p>
      <h3>How long will we keep your data</h3>
      <p>
        We will keep your health data for as long as clinically relevant, but no
        longer than 90 days from date of data generation. Your date of birth
        will be processed at the point of registration and will not be stored in
        our systems. Once the relevant period has expired your data will be
        securely deleted.
      </p>
      <h3>Your rights under the UK GDPR</h3>
      <p>
        The UK GDPR gives people eight rights in relation to their own data, we
        have detailed below how these rights apply to the processing of your
        data outlined in this Privacy Notice.
      </p>
      <ol>
        <li>
          <strong>Right to be informed</strong> &ndash; you have the right to
          clear and complete information about the processing of your personal
          data which is provided in this Privacy Notice
        </li>
        <li>
          <strong>Right of access</strong> &ndash; you have the right to confirm
          whether your personal data is being processed by us, or on our behalf,
          and to request a copy of that data and information about how it is
          being processed
        </li>
        <li>
          <strong>Right to rectification</strong> &ndash; you have the right to
          have any inaccuracies in your personal data we hold corrected
        </li>
        <li>
          <strong>
            Right to erasure (&lsquo;right to be forgotten&rsquo;)
          </strong>{" "}
          &ndash; you have the right to have your personal data held by us for
          this purpose deleted, however this may not apply to other data we hold
          on you that relies on a different lawful basis for processing
        </li>
        <li>
          <strong>Right to restriction of processing</strong> &ndash; you have
          the right to stop the processing of your personal data for a limited
          period of time if you believe it is inaccurate
        </li>
        <li>
          <strong>Right to data portability</strong> &ndash; the UK GDPR
          provides the right to request your data be transferred from one
          controller to another controller in a commonly used machine-readable
          format in certain circumstances, however this does not apply where the
          data is collected indirectly (e.g. via a third party)
        </li>
        <li>
          <strong>Right to object</strong> &ndash; you have the right to
          withdraw consent for the processing of your data as outlined in this
          Privacy Notice, however this may not apply to other data we hold on
          you that relies on a different lawful basis for processing.
        </li>
        <li>
          <strong>Automated decision-making</strong> &ndash; the UK GDPR
          provides the right for human intervention in decision-making;
          automated decision making is not used for the processing outlined in
          this Privacy Notice so this right does not apply for these purposes of
          processing
        </li>
      </ol>
      <p>
        If you require more information about your rights you can find out more{" "}
        <a href="https://ico.org.uk/for-organisations/uk-gdpr-guidance-and-resources/individual-rights/individual-rights/">
          here
        </a>
        , or by contacting us using the details provided at the top of this
        Privacy Notice. If you would like to exercise any of your rights you
        should contact us using the details provided at the top of this Privacy
        Notice.
      </p>
      <p>
        If you have contacted us and are still not happy with how we process
        your personal data you have the right to lodge a complaint with the
        Information Commissioner&rsquo;s Office (ICO); find our more{" "}
        <a href="https://ico.org.uk/make-a-complaint/data-protection-complaints/personal-information-complaint/">
          here
        </a>
        . Our registration reference with the ICO is{" "}
        {trustDetails.dataController.icoRef}.
      </p>
      <h3>Where we obtained your data (third parties)</h3>
      <p>
        In addition to data captured directly, we obtained data for the
        processing outlined in this Privacy Notice from the following sources:
      </p>
      <ul>
        <li>Dexcom, Inc. &ndash; via their external APIs</li>
      </ul>
    </>
  );
}
