import React from "react";
import { Outlet, useParams } from "react-router-dom";
import GetTrust from "../services/TrustService";
import Footer from "../components/Footer";
import dexcomLogo from "../img/logos/dexcom-logo-green-rgb.svg";

export default function DexcomLayout() {
  const { trust } = useParams();
  let trustDetails = GetTrust(trust);

  return (
    <div>
      <header>
        <div className="wrapper">
          <div className="logos">
            <img src={dexcomLogo} alt="Dexcom" className="dexcom-logo" />
            {trustDetails.trustLogo}
          </div>
        </div>
      </header>
      <div className="wrapper">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}
