import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function ValidationError({ message }) {
  return (
    message && (
      <p className="error">
        <FontAwesomeIcon
          icon={icon({ name: "triangle-exclamation", style: "solid" })}
        />{" "}
        {message}
      </p>
    )
  );
}
