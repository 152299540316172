import React from "react";
import Box from "../../components/Box";
import ConnectingAnimation from "../../components/ConnectingAnimation";

export default function Success({ trustDetails }) {
  return (
    <Box>
      <p>
        That’s all set up! Your device data has been matched to your{" "}
        {trustDetails.trustName} record and will now be accessible to your
        clinicians.
      </p>
      <ConnectingAnimation complete={true} />
      <p>
        Should you wish to change your permissions at any time, you can always
        do so by logging back into your Dexcom account.
      </p>
    </Box>
  );
}
