import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import "../scss/components/connecting-animation.scss";

export default function ConnectingAnimation({ complete = false }) {
  return (
    <div className={"connecting-animation" + (!complete ? " animate" : "")}>
      <FontAwesomeIcon icon={icon({ name: "mobile-screen", style: "solid" })} />{" "}
      <div className="connection-container">
        <div className="connection"></div>
      </div>{" "}
      <FontAwesomeIcon icon={icon({ name: "user-doctor", style: "solid" })} />
    </div>
  );
}
