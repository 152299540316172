export default function Fetch(url, requestBody, retryAttempts = 2) {
  console.info({ url, requestBody });
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(requestBody),
  };
  return HttpFetchWithRetry(url, requestOptions, retryAttempts).then(
    (responseObj) => {
      console.info(responseObj);
      if (!responseObj.isSuccessful) {
        throw new Error(`Response from ${url} indicates failure`);
      }

      return responseObj.content;
    }
  );
}

function HttpFetchWithRetry(url, requestOptions, retryAttempts) {
  return fetch(url, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      if (retryAttempts > 0) {
        console.info(
          `HTTP response status ${response.status} received from ${url}. Retries remaining: ${retryAttempts}`
        );
        return HttpFetchWithRetry(url, requestOptions, retryAttempts - 1);
      } else {
        throw new Error(
          `HTTP response status ${response.status} received from ${url}. No more retries`
        );
      }
    }
  });
}
