import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Challenge from "./stages/Challenge";
import ReturnFromDexcom from "./stages/ReturnFromDexcom";
import Success from "./stages/Success";
import Failure from "./stages/Failure";
import GetTrust from "../services/TrustService";

export default function AuthFlow() {
  const [stage, setStage] = useState("Unknown");
  const [token, setToken] = useState("Unknown");
  const [authCode, setAuthCode] = useState("Unknown");

  const { trust } = useParams();
  let trustDetails = GetTrust(trust);

  useEffect(() => {
    (async function () {
      const urlParams = new URLSearchParams(window.location.search);
      if (stage === "Unknown") {
        if (urlParams.has("token")) {
          // Start of journey
          setToken(urlParams.get("token"));
          setStage("Challenge");
        } else if (urlParams.has("code") && urlParams.has("state")) {
          // Land from Dexcom
          setToken(urlParams.get("state"));
          setAuthCode(urlParams.get("code"));
          setStage("ReturnFromDexcom");
        } else {
          // Invalid
          setStage("Failure");
        }
      }
      document.title = `${trustDetails.trustName} | Data-Led Care | Link my Dexcom Account`;
    })();
  }, [stage]);

  switch (stage) {
    case "Challenge":
      return <Challenge trustDetails={trustDetails} token={token} />;
    case "ReturnFromDexcom":
      return (
        <ReturnFromDexcom
          trustDetails={trustDetails}
          token={token}
          authCode={authCode}
          handleSuccess={() => {
            setStage("Success");
          }}
          handleFailure={() => {
            setStage("Failure");
          }}
        />
      );
    case "Success":
      return <Success trustDetails={trustDetails} />;
    default:
      return <Failure />;
  }
}
