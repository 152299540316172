import { Outlet } from "react-router-dom";
import ErrorBoundary from "../error/ErrorBoundary";

const Layout = () => {
  return (
    <>
      <div>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </div>
    </>
  );
};

export default Layout;
