import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function CopyToClipboard({ textToCopy }) {
  const [textCopied, setTextCopied] = useState(false);

  return (
    <a
      href="#"
      onClick={(event) => {
        event.preventDefault();
        navigator.clipboard.writeText(textToCopy);
        setTextCopied(true);
      }}
      className="cta ml-auto mr-auto"
    >
      Copy to clipboard
      {(textCopied && (
        <FontAwesomeIcon
          icon={icon({
            name: "check",
            style: "solid",
          })}
        />
      )) || (
        <FontAwesomeIcon
          icon={icon({
            name: "copy",
            style: "regular",
          })}
        />
      )}
    </a>
  );
}
