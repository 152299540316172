import React from "react";
import TextField from "./TextField";

export default function DatePicker({
  dateValidationScheme = dateValidationSchemes.PAST_DATES,
  registerGetDateCallback,
  initialValue,
}) {
  const formatInput = (input) => {
    if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
    var values = input.split("/").map(function (v) {
      return v.replace(/\D/g, "");
    });
    if (values[0]) values[0] = checkValue(values[0], 31, values.length > 1);
    if (values[1]) values[1] = checkValue(values[1], 12, values.length > 2);
    if (values[2]) values[2] = checkYear(values[2]);
    var output = values.map(function (v, i) {
      return v.length === 2 && i < 2 ? v + " / " : v;
    });

    const formattedValue = output.join("").substr(0, 14);
    return formattedValue;
  };

  const getErrorMessage = (input) => {
    let dateObject = stringToDate(input);

    if (!dateObject) {
      return "Please enter a valid date in the format DD / MM / YYYY";
    } else {
      if (dateValidationScheme === dateValidationSchemes.OVER_18) {
        var age = _calculateAge(dateObject);
        if (age < 18) {
          return "You must be at least 18 years old to create an account.";
        }
      } else if (dateValidationScheme === dateValidationSchemes.PAST_DATES) {
        if (dateObject > new Date().setHours(0, 0, 0, 0)) {
          return "Please enter a date in the past.";
        }
      } else if (
        dateValidationScheme === dateValidationSchemes.FUTURE_DATES ||
        dateValidationScheme === dateValidationSchemes.FUTURE_DATES_6M
      ) {
        if (dateObject < new Date().setHours(0, 0, 0, 0)) {
          return "Please enter a date in the future.";
        }
      }
      if (dateValidationScheme === dateValidationSchemes.FUTURE_DATES_6M) {
        if (dateObject > new Date().setMonth(new Date().getMonth() + 6)) {
          return "Please enter a date in the next 6 months.";
        }
      }
    }
    return false;
  };

  const getValidDate = () => {
    const input = getStringValueCallbackRef.current();
    if (getErrorMessage(input)) {
      return false;
    } else {
      return stringToDate(input);
    }
  };

  registerGetDateCallback(getValidDate);

  const getStringValueCallbackRef = React.useRef();

  function registerGetStringValueCallback(callback) {
    getStringValueCallbackRef.current = callback;
  }

  return (
    <TextField
      registerGetValueCallback={registerGetStringValueCallback}
      example={"21 / 03 / 1994"}
      formatter={formatInput}
      getErrorMessage={getErrorMessage}
      initialValue={dateToString(initialValue)}
    />
  );
}

export const dateValidationSchemes = {
  OVER_18: "Permit only dates over 18 years ago",
  FUTURE_DATES: "Permit only dates in the future",
  FUTURE_DATES_6M: "Permit only dates in next 6 months",
  PAST_DATES: "Permit only dates in the past",
};

function checkValue(str, max, fullNumber) {
  if (str.charAt(0) !== "0" || str === "00") {
    var num = parseInt(str);
    if (isNaN(num) || num <= 0 || num > max) num = 1;
    str =
      (num > parseInt(max.toString().charAt(0)) || fullNumber) &&
      num.toString().length === 1
        ? "0" + num
        : num.toString();
  }
  return str;
}

function checkYear(str) {
  const thisYear = new Date().getFullYear();
  const num = parseInt(str);
  if (str.length === 2 && num !== 19 && num !== 20) {
    if (isNaN(num) || num < 0) return str;
    // Assume 20 years in the future, or 80 in the past
    if (num < thisYear - 2000 + 20) {
      str = (2000 + num).toString();
    } else {
      str = (1900 + num).toString();
    }
  }
  return str;
}

function _calculateAge(birthday) {
  // birthday is a date
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

const stringToDate = (input) => {
  try {
    const val = input.replace(/\s/g, "");
    var regex = RegExp(
      /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/
    );
    if (regex.test(val)) {
      var dateParts = val.split("/");
      return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    }
  } catch {}
  return false;
};

const dateToString = (date) => {
  if (date !== undefined) {
    const day = "0" + date.getDate();
    const month = "0" + (date.getMonth() + 1); // JavaScript months start at zero
    return `${day.substring(day.length - 2)} / ${month.substring(
      month.length - 2
    )} / ${date.getFullYear()}`;
  }
  return "";
};
