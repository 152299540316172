import React, { useState } from "react";
import ValidationError from "./ValidationError";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function TextField({
  registerGetValueCallback,
  fieldDescription,
  regex,
  example,
  formatter = (input) => {
    return input.trim();
  },
  getErrorMessage = (input) => {
    if (regex.test(input) === false) {
      return `Please enter a valid ${fieldDescription} in the format ${example}`;
    } else {
      return false;
    }
  },
  initialValue = "",
}) {
  const [stringValue, setStringValue] = useState(initialValue);
  const [validationError, setValidationError] = useState(false);

  const onInputHandler = (e) => {
    var input = e.target.value;
    const formattedValue = formatter(input);
    e.target.value = formattedValue;
    setStringValue(formattedValue);
  };

  const getValidInput = () => {
    const errorMessage = getErrorMessage(stringValue);
    setValidationError(errorMessage);
    return errorMessage ? false : stringValue;
  };

  registerGetValueCallback(getValidInput);

  return (
    <>
      <div className="input-wrapper">
        <FontAwesomeIcon icon={icon({ name: "pencil", style: "solid" })} />
        <input
          type="text"
          placeholder={`e.g. ${example}`}
          onInput={onInputHandler}
          onBlur={getValidInput}
          value={stringValue}
        />
      </div>
      <ValidationError message={validationError} />
    </>
  );
}
