import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Box from "../components/Box";
import Label from "../components/formFields/Label";
import DatePicker from "../components/formFields/DatePicker";
import TextField from "../components/formFields/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import GetTrust from "../services/TrustService";
import ConnectingAnimation from "../components/ConnectingAnimation";
import CopyToClipboard from "../components/CopyToClipboard";
import Fetch from "../services/FetchService";

export default function GetPatientStatus() {
  const [demographics, setDemographics] = useState({});
  const [failure, setFailure] = useState(false);
  const [pending, setPending] = useState(false);
  const [patientLocated, setPatientLocated] = useState(false);
  const [sharingAuthorized, setSharingAuthorized] = useState(false);
  const [sharingFunctional, setSharingFunctional] = useState(false);
  const [sharingUrl, setSharingUrl] = useState();
  const [blockedUntil, setBlockedUntil] = useState();
  const [attemptsRemaining, setAttemptsRemaining] = useState();

  const { trust } = useParams();
  let trustDetails = GetTrust(trust);

  const getDateCallbackRef = React.useRef();
  const getHostpitalNumberCallbackRef = React.useRef();

  function registerGetDateCallback(callback) {
    getDateCallbackRef.current = callback;
  }

  function registerGetHospitalNumberCallback(callback) {
    getHostpitalNumberCallbackRef.current = callback;
  }

  const handleSubmitPress = async (event) => {
    const dateOfBirth = getDateCallbackRef.current();
    const hospitalNumber = getHostpitalNumberCallbackRef.current();
    console.info(
      `Handling submit with dob ${dateOfBirth} and hospital number ${hospitalNumber}`
    );
    if (dateOfBirth && hospitalNumber) {
      setDemographics({
        dateOfBirth: dateOfBirth,
        hospitalNumber: hospitalNumber,
      });
      validateToken({
        dateOfBirth: dateOfBirth,
        hospitalNumber: hospitalNumber,
      });
    }
    event.preventDefault();
  };

  function getShareUrl(linkingToken) {
    const thisUrl = window.location.href;
    const baseUrl = thisUrl.substring(0, thisUrl.lastIndexOf("/"));
    return `${baseUrl}/share?token=${linkingToken}`;
  }

  async function validateToken(demographics) {
    if (blockedUntil > new Date()) {
      return;
    }
    setPending(true);
    setFailure(false);
    console.info({ demographics: demographics });
    const requestBody = {
      trustOrganisation: trustDetails.apiShortName,
      apiOrganisation: "Dexcom",
      hospitalNumber: demographics.hospitalNumber,
      yearOfBirth: demographics.dateOfBirth.getFullYear(),
      monthOfBirth: demographics.dateOfBirth.getMonth() + 1, // JavaScript months start at zero
      dayOfBirth: demographics.dateOfBirth.getDate(),
    };
    Fetch("/api/GetPatientSharingStatus", requestBody)
      .then((response) => {
        const patientLocated = response.patientLocated;
        const linkingToken = response.linkingToken;
        if (patientLocated) {
          if (linkingToken) {
            setPatientLocated(true);
            setSharingUrl(getShareUrl(linkingToken));
            setSharingAuthorized(response.sharingAuthorized);
            setSharingFunctional(response.sharingFunctional);
            setPending(false);
          } else {
            throw new Error(
              "Response indicates patientLocated but contains no linkingToken"
            );
          }
        } else {
          if (response.rateLimiting.blockedUntil) {
            setBlockedUntil(Date.parse(response.rateLimiting.blockedUntil));
            setFailure("blocked");
            setPending(false);
          } else {
            setAttemptsRemaining(response.rateLimiting.attemptsRemaining);
            setFailure("data");
            setPending(false);
          }
        }
      })
      .catch((error) => {
        console.error(error);
        setFailure("technical");
        setPending(false);
      });
  }

  function renderFailureMessage() {
    switch (failure) {
      case "data":
        return (
          <>
            Sorry, we couldn&rsquo;t locate the patient from the information
            supplied; please check and resubmit.{" "}
            {attemptsRemaining < 4 && (
              <>
                Please note, this hospital number will be blocked after{" "}
                {attemptsRemaining} further validation{" "}
                {attemptsRemaining === 1 ? <>failure</> : <>failures</>}.
              </>
            )}
          </>
        );
      case "blocked":
        var timeUntilUnblocked = blockedUntil - new Date();
        var minsUntilUnblocked = Math.ceil(timeUntilUnblocked / 1000 / 60);
        return (
          <>
            Sorry, that&rsquo;s too many validation failures. You&rsquo;ll need
            to wait {minsUntilUnblocked}{" "}
            {minsUntilUnblocked === 1 ? <>minute</> : <>minutes</>} before
            trying again.
          </>
        );
      default:
        return (
          <>
            Sorry, something&rsquo;s not worked on our side. Please try again.
          </>
        );
    }
  }

  useEffect(() => {
    document.title = `${trustDetails.trustName} | Data-Led Care | Check Patient Status`;
  });

  return (
    <div>
      {(patientLocated && <p>Great, thanks for confirming.</p>) || (
        <p>Please enter the details of the patient.</p>
      )}
      <Box>
        {pending && (
          <div className="flex">
            <p>
              <FontAwesomeIcon
                icon={icon({
                  name: "spinner",
                  style: "solid",
                })}
                spinPulse
                className="green"
              />{" "}
              Checking our records...
            </p>
          </div>
        )}
        {failure && !pending && (
          <p className="error">
            <FontAwesomeIcon
              icon={icon({ name: "triangle-exclamation", style: "solid" })}
            />{" "}
            {renderFailureMessage()}
          </p>
        )}
        {!patientLocated && !pending && (
          <div>
            <p>
              Hospital number and date of birth must be entered correctly in
              order for the patient&rsquo;s status to be displayed.
            </p>
            <form onSubmit={handleSubmitPress}>
              <Label text={"Hospital number"}>
                <TextField
                  registerGetValueCallback={registerGetHospitalNumberCallback}
                  regex={/^[A-Z]{2}\d{4}$/}
                  fieldDescription={"hospital number"}
                  example={"AB1234"}
                  formatter={(input) => {
                    setFailure(false);
                    setBlockedUntil(null);
                    return input.trim().toUpperCase();
                  }}
                  initialValue={demographics?.hospitalNumber}
                />
              </Label>
              <Label text={"Date of birth"}>
                <DatePicker
                  registerGetDateCallback={registerGetDateCallback}
                  initialValue={demographics?.dateOfBirth}
                />
              </Label>
              <label>
                <button type="submit" className="cta cta--wide">
                  Check status
                  <FontAwesomeIcon
                    icon={icon({
                      name: "angle-right",
                      style: "solid",
                    })}
                  />
                </button>
              </label>
            </form>
          </div>
        )}
        {patientLocated && (
          <>
            <p>
              The patient with hospital number {demographics.hospitalNumber} has
              been successfully located and verified.
            </p>
            {(sharingFunctional && (
              <>
                <ConnectingAnimation complete={true} />
                <p>
                  They have already granted data sharing permissions, for{" "}
                  {trustDetails.trustName}, so no further action is required.
                </p>
              </>
            )) || (
              <>
                {(sharingAuthorized && (
                  <>
                    <p>
                      They may have previously granted data sharing permissions,
                      for {trustDetails.trustName}, but are currently unable to
                      access their data.
                    </p>
                    <p>
                      The patient may have revoked their permission, so please
                      ask the patient to grant permission again via the link
                      below.
                    </p>
                  </>
                )) || (
                  <p>
                    Please ask the patient to grant permission via the link
                    below.
                  </p>
                )}
                <p>They will need to know:</p>
                <ul>
                  <li>Their date of birth</li>
                  <li>
                    Their Dexcom login credentials (username/email and password)
                  </li>
                </ul>
                <p className="flex flex--break-medium">
                  <a
                    href={sharingUrl}
                    target="_blank"
                    className="cta ml-auto mr-auto"
                  >
                    Grant now{" "}
                    <FontAwesomeIcon
                      icon={icon({
                        name: "arrow-up-right-from-square",
                        style: "solid",
                      })}
                    />
                  </a>
                  <CopyToClipboard textToCopy={sharingUrl} />
                </p>
                <p>
                  The patient can complete the process on this device, or you
                  can copy the link and send it directly to the patient, to
                  complete on their own device.
                </p>
              </>
            )}
            <p className="mt-lg">
              <Link
                reloadDocument
                className="cta cta--wide cta-secondary ml-auto mr-auto"
              >
                Find another patient{" "}
                <FontAwesomeIcon
                  icon={icon({
                    name: "magnifying-glass",
                    style: "solid",
                  })}
                />
              </Link>
            </p>
          </>
        )}
      </Box>
    </div>
  );
}
