import React, { useState, useEffect } from "react";
import Box from "../../components/Box";
import Label from "../../components/formFields/Label";
import DatePicker from "../../components/formFields/DatePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import Fetch from "../../services/FetchService";

export default function Challenge({ trustDetails, token }) {
  const [demographics, setDemographics] = useState({});
  const [failure, setFailure] = useState(false);
  const [pending, setPending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [authUrl, setAuthUrl] = useState();
  const [blockedUntil, setBlockedUntil] = useState();
  const [attemptsRemaining, setAttemptsRemaining] = useState();

  const getDateCallbackRef = React.useRef();

  function registerGetDateCallback(callback) {
    getDateCallbackRef.current = callback;
  }

  useEffect(() => {
    (async function () {
      const requestBody = {
        patientRegistrationToken: token,
        trustOrganisation: trustDetails.apiShortName,
      };
      Fetch("/api/PatientLand", requestBody).catch((error) => {
        console.error(error);
      });
    })();
  }, [trustDetails, token]);

  const handleSubmitPress = async (event) => {
    const dateOfBirth = getDateCallbackRef.current();
    console.info(`Handling submit with dob ${dateOfBirth}`);
    if (dateOfBirth) {
      setDemographics({ dateOfBirth: dateOfBirth });
      validateToken({ dateOfBirth: dateOfBirth });
    }
    event.preventDefault();
  };

  async function validateToken(demographics) {
    if (blockedUntil > new Date()) {
      return;
    }
    setPending(true);
    setFailure(false);
    console.info({ demographics: demographics });
    var startTime = Date.now();
    const requestBody = {
      dexcomLinkingToken: token,
      org: trustDetails.apiShortName,
      yearOfBirth: demographics.dateOfBirth.getFullYear(),
      monthOfBirth: demographics.dateOfBirth.getMonth() + 1, // JavaScript months start at zero
      dayOfBirth: demographics.dateOfBirth.getDate(),
    };
    Fetch("/api/ValidateToken", requestBody)
      .then((response) => {
        const success = response.isValid;
        if (success) {
          // Display this screen for at least 2.5 seconds
          var timeDelta = Date.now() - startTime;
          setTimeout(() => {
            setAuthUrl(response.authURL);
            setSuccess(true);
            setPending(false);
          }, 2500 - timeDelta);
        } else {
          if (response.rateLimiting.blockedUntil) {
            setBlockedUntil(Date.parse(response.rateLimiting.blockedUntil));
            setFailure("blocked");
            setPending(false);
          } else {
            setAttemptsRemaining(response.rateLimiting.attemptsRemaining);
            setFailure("data");
            setPending(false);
          }
        }
      })
      .catch((error) => {
        console.error(error);
        setFailure("technical");
        setPending(false);
      });
  }

  function renderFailureMessage() {
    switch (failure) {
      case "data":
        return (
          <>
            Sorry, this information doesn&rsquo;t match our records; please
            check and resubmit.{" "}
            {attemptsRemaining < 4 && (
              <>
                Please note, this link will be blocked after {attemptsRemaining}{" "}
                further validation{" "}
                {attemptsRemaining === 1 ? <>failure</> : <>failures</>}.
              </>
            )}
          </>
        );
      case "blocked":
        var timeUntilUnblocked = blockedUntil - new Date();
        var minsUntilUnblocked = Math.ceil(timeUntilUnblocked / 1000 / 60);
        return (
          <>
            Sorry, that&rsquo;s too many validation failures. You&rsquo;ll need
            to wait {minsUntilUnblocked}{" "}
            {minsUntilUnblocked === 1 ? <>minute</> : <>minutes</>} before
            trying again.
          </>
        );
      default:
        return (
          <>
            Sorry, something&rsquo;s not worked on our side. Please try again.
          </>
        );
    }
  }

  return (
    <div>
      {(success && <p>Great, thanks for confirming.</p>) || (
        <>
          <p>
            {trustDetails.trustName} would like to be able to join the data from
            your Dexcom device to your medical record in order to enable the
            best view of you as a patient and to provide you with the best
            possible care in its diabetes services.
          </p>
          <p>
            We have partnered with a company called Factor 50 to provide this
            service for us.​
          </p>
          <p>
            Before we can match your Dexcom data to your medical record at{" "}
            {trustDetails.trustName}, we need to check that we have the right
            person.​
          </p>
        </>
      )}
      <Box>
        {pending && (
          <div className="flex">
            <p>
              <FontAwesomeIcon
                icon={icon({
                  name: "spinner",
                  style: "solid",
                })}
                spinPulse
                className="green"
              />{" "}
              Checking our records...
            </p>
          </div>
        )}
        {failure && !pending && (
          <p className="error">
            <FontAwesomeIcon
              icon={icon({ name: "triangle-exclamation", style: "solid" })}
            />{" "}
            {renderFailureMessage()}
          </p>
        )}
        {!success && !pending && (
          <div>
            <p>
              Please confirm your date of birth so we can check our records.
            </p>
            <form onSubmit={handleSubmitPress}>
              <Label text={"Date of birth"}>
                <DatePicker
                  registerGetDateCallback={registerGetDateCallback}
                  initialValue={demographics?.dateOfBirth}
                />
              </Label>
              <label>
                <button type="submit" className="cta cta--wide">
                  Confirm
                  <FontAwesomeIcon
                    icon={icon({
                      name: "angle-right",
                      style: "solid",
                    })}
                  />
                </button>
              </label>
            </form>
          </div>
        )}
        {success && (
          <div className="mb-lg">
            <p>
              We&rsquo;ll now ask you to log into your Dexcom account, where you
              can grant permission for the sharing of Dexcom data with{" "}
              {trustDetails.dataController.name}.
            </p>
            <p className="mt-lg">
              <a
                className="cta cta--wide ml-auto mr-auto"
                href={authUrl}
                rel="noreferrer"
              >
                Log in to Dexcom
                <FontAwesomeIcon
                  icon={icon({
                    name: "arrow-up-right-from-square",
                    style: "solid",
                  })}
                />
              </a>
            </p>
          </div>
        )}
      </Box>
    </div>
  );
}
